.Header {
  width: 100%;
  position: fixed;
  top: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1.8rem 0;
  background: #ffffff;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.12);
  z-index: 10;

  &__Back {
    margin-left: 1.6rem;
  }
  &__Head {
    font-style: normal;
    font-weight: 500;
    font-size: 1.8rem;
    line-height: 2.1rem;
    margin-left: 2.8rem;
    color: rgba(0, 0, 0, 0.87);
  }
}
