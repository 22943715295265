.QuizOfDay {
  background: #ffffff;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  border-radius: 6px;
  margin-top: 2.2rem;

  &__Names {
    padding-bottom: 1.9rem;
  }

  &__Footer {
    background: #fafafa;
    border-top: 1px solid #e5e5e5;
    box-sizing: border-box;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 150%;
    text-decoration-line: underline;
    color: #348186;
    padding: 1.3rem 2.4rem;
  }
}
