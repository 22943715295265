.RegisterModal {
  display: flex;
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.6);
  -webkit-transition: opacity 1s ease-out, bottom 1s ease-out;
  -moz-transition: opacity 1s ease-out, bottom 1s ease-out;
  -o-transition: opacity 1s ease-out, bottom 1s ease-out;
  transition: opacity 1s ease-out, bottom 1s ease-out;

  &__Container {
    width: 100%;
    // height: 58rem;
    position: absolute;
    bottom: 0;
    background: #ffffff;
    box-shadow: 0px 12px 12px rgba(0, 0, 0, 0.12);
    border-radius: 12px 12px 0px 0px;
    overflow: auto;

    &--Header {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      font-size: 18px;
      color: rgba(0, 0, 0, 0.87);
      border-bottom: 1px solid rgba(255, 255, 255, 0.2);
      border-radius: 16px 16px 0px 0px;
      position: relative;

      &__Close {
        position: absolute;
        top: 2.4rem;
        right: 2.4rem;
      }

      &__Img {
        width: 100%;
      }
    }

    &--Body {
      padding: 1rem 2rem 2rem;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      text-align: justify;
      overflow: auto;
      padding-bottom: 16rem;

      &__Oops {
        font-weight: 500;
        font-size: 1.6rem;
        line-height: 150%;
        text-decoration-line: underline;
        color: #2c7478;
        mix-blend-mode: normal;
        margin-bottom: 1.8rem;
      }

      &__Para1 {
        font-weight: bold;
        font-size: 1.8rem;
        line-height: 150%;
        color: rgba(0, 0, 0, 0.87);
        mix-blend-mode: normal;
        margin-bottom: 1.6rem;
      }

      &__Para2 {
        font-weight: 450;
        font-size: 1.6rem;
        line-height: 150%;
        color: rgba(0, 0, 0, 0.6);
        mix-blend-mode: normal;
        margin-bottom: 1.2rem;
      }

      &__BtnDiv {
        display: flex;
        flex-direction: row;
        &--Btn {
          font-weight: 450;
          font-size: 1.4rem;
          line-height: 1.8rem;
          color: #2c7478;
          padding: 1.2rem;
          background: #ffffff;
          border: 1px solid #2c7478;
          border-radius: 4px;
        }
      }
    }

    &--Footer {
      width: 100%;
      position: absolute;
      bottom: 0;
      padding: 2rem;

      &__Btn {
        width: 100%;
        background: #e5e5e5;
        border-radius: 4px;
        font-weight: 500;
        font-size: 1.8rem;
        line-height: 133%;
        text-align: center;
        color: #ffffff;
        border: none;
        padding: 1.2rem 0;
      }
    }
  }
}

.modal {
  &__modalContainer::-webkit-scrollbar {
    width: 0 !important;
    overflow: -moz-scrollbars-none;
  }
}
