.EventComponent {
  background: #ffffff;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  border-radius: 6px;
  padding: 2rem;
  margin-top: 0.8rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  &__Col1 {
    width: 20%;
  }
  &__Col2 {
    width: 60%;
    margin-left: 1rem;
    &--Par1 {
      margin-bottom: 0.8rem;
      font-weight: bold;
      font-size: 1.8rem;
      line-height: 150%;
      color: rgba(0, 0, 0, 0.87);
    }

    &--Par2 {
      font-weight: 500;
      font-size: 1.6rem;
      line-height: 150%;
      text-decoration-line: underline;
      color: #348186;
    }
  }
  &__Col3 {
    width: 15%;
    text-align: center;

    &--Par1, &--Par2 {
      font-weight: 450;
      font-size: 1.6rem;
      line-height: 150%;
      color: rgba(0, 0, 0, 0.87);
    }
  }
}
