.Load {
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: 1;
  opacity: 0.5;
  background: #ffffff;
  top: 0;
}

@media(min-width: 1000px){
    .Load{
        width:60%;
    }
}