.QuizForm {
  position: relative;

  &__Body {
    margin-top: 6.5rem;
    padding-bottom: 2.4rem;
    &--Img {
      width: 100%;
    }

    &--Question {
      font-weight: 500;
      font-size: 1.4rem;
      line-height: 150%;
      letter-spacing: 0.5px;
      color: rgba(0, 0, 0, 0.6);
      padding: 0rem 2.4rem 0.8rem;
    }

    &--Para1,
    &--Para2 {
      font-weight: 450;
      font-size: 1.6rem;
      line-height: 150%;
      color: rgba(0, 0, 0, 0.87);
      padding: 0 2.4rem;
    }

    &--Para1 {
      padding-bottom: 3rem;
    }
    &--Para2 {
      padding-bottom: 2.4rem;
    }
    &--ImgBg {
      padding: 0 2.4rem;
      width: 100%;
    }
    &--DownBtn {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      border: 1px solid #414141;
      background: #ffffff;
      border-radius: 4px;
      width: 90%;
      padding: 1rem 0;
      margin: 2.4rem 2.4rem 4rem;
      &__Label {
        margin-left: 1.6rem;
      }
    }
    &--Answer {
      font-weight: 500;
      font-size: 1.4rem;
      line-height: 150%;
      letter-spacing: 0.5px;
      color: rgba(0, 0, 0, 0.6);
      padding: 0 2.4rem 0.8rem;
    }
    &--InputBox {
      background: #ffffff;
      border: 1px solid #e5e5e5;
      box-sizing: border-box;
      border-radius: 6px;
      margin: 0 2.4rem;
      width: 90%;
      padding: 1.6rem;
      font-weight: 450;
      font-size: 1.4rem;
      line-height: 150%;
    }

    &--Submit {
      background: #529fa4;
      border-radius: 4px;
      font-weight: 500;
      font-size: 1.8rem;
      line-height: 133%;
      text-align: center;
      color: #ffffff;
      margin: 3.6rem 2.4rem;
      width: 90%;
      border: none;
      padding: 1.2rem 0;
    }
    &--BtnDone {
      background: #ffffff;
      border-radius: 4px;
      font-weight: 500;
      font-size: 1.8rem;
      line-height: 90%;
      text-align: center;
      color: #348186;
      width: 90%;
      border: none;
      padding: 1.4rem 0 1.2rem;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      margin: 3.6rem 2.4rem;
      border: 1px solid #e5e5e5;
      box-sizing: border-box;

      &__Para {
        margin-left: 0.8rem;
      }
    }
  }
}

textarea:focus {
  outline: none;
}
