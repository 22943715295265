.KidsCodingWeek {
  background-image: url("../../images/kidsBg.svg");
  background-position: center;
  background-size: cover;
  padding: 2rem;
  border-radius: 6px;

  &__Para1 {
    font-weight: bold;
    font-size: 2rem;
    line-height: 150%;
    color: #ffffff;
    margin-bottom: 1rem;
  }

  &__Div {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 2rem;

    &--Para {
      font-weight: 500;
      font-size: 1.6rem;
      line-height: 150%;
      color: #ffffff;
      margin-left: 1rem;
    }
  }

  &__Para2 {
    font-weight: 450;
    font-size: 1.4rem;
    line-height: 140%;
    color: rgba(255, 255, 255, 0.87);
    width: 60%;
  }
}
