.QuizDay {
  margin-top: 2.4rem;

  &__Head {
    font-weight: 450;
    font-size: 2.2rem;
    line-height: 150%;
    color: rgba(0, 0, 0, 0.87);
  }

  &__Main {
    margin-top: 0.6rem;
    background: #ffffff;
    border: 1px solid #e5e5e5;
    box-sizing: border-box;
    border-radius: 6px;
  }

  &__Body {
    width: 100%;
    display: flex;
    flex-direction: row;

    &--Col1 {
      width: 60%;
      padding: 2.4rem 0 2.4rem 2.4rem;

      &__Para1 {
        font-weight: bold;
        font-size: 1.8rem;
        line-height: 150%;
        color: rgba(0, 0, 0, 0.87);
        margin-bottom: 1.2rem;
      }

      &__Para2 {
        font-weight: 450;
        font-size: 1.6rem;
        line-height: 130%;
        color: rgba(0, 0, 0, 0.6);
        margin-bottom: 1.6rem;
      }

      &__Btn {
        background: #9F5EB5;
        border-radius: 4px;
        font-weight: 500;
        font-size: 1.6rem;
        line-height: 150%;
        color: #ffffff;
        padding: 0.8rem 3rem;
        border: none;
      }

      &__BtnDone {
        background: #ffffff;
        border: 1px solid #e5e5e5;
        border-radius: 4px;
        font-weight: 500;
        font-size: 1.6rem;
        line-height: 150%;
        color: #57649e;
        padding: 0.8rem 3rem;
        box-sizing: border-box;
        border-radius: 4px;
        display: flex;
        flex-direction: row;
        align-items: center;

        &--Para {
          margin-left: 0.8rem;
        }
      }
    }

    &--Col2 {
      width: 40%;
      &__Img {
        width: 90%;
        float: right;
      }
    }
  }

  &__Footer {
    width: 100%;
    background: #fafafa;
    border-top: 1px solid #e5e5e5;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 1.6rem;

    &--Img {
      width: 10%;
    }

    &--Para {
      width: 80%;
      font-weight: 450;
      font-size: 1.4rem;
      line-height: 150%;
      color: rgba(0, 0, 0, 0.87);
      margin-left: 1.6rem;
    }
  }
}
