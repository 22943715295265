.navigation {
  padding: 1.2rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $color-primary-white;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0px;
  width: 100%;
  z-index: 100;

  @include respond(tab-land) {
    padding: 0.5rem 0;
  }

  @include respond(large-screen) {
    padding: 0.5rem 0;
  }

  @include respond(medium-screen) {
    padding: 0.5rem 0;
  }

  @include respond(ipad) {
    padding: 1rem 0;
  }

  @include respond(phone) {
    padding: 1rem 0;
  }

  &__logo {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 16rem;
    height: 4rem;

    @include respond(phone) {
      width: 14rem;
      height: 3.5rem;
    }

    &--img {
      margin-left: 10rem;
      width: 100%;
      height: 100%;
      @include respond(phone) {
        margin-left: 1rem;
      }
    }

    &--btn {
      width: 100%;
      height: 100%;
    }

    &--applicantMain {
      width: 100%;
      display: flex;
      flex-direction: row;
    }

    &--applicant {
      width: 50%;
      background: #0e3a62;
      font-size: 1.6rem;
      font-weight: 600;
      margin-left: 3rem;
      padding: 1rem 1.5rem;
    }

    &--job {
      width: 50%;
      background: #0e3a62;
      font-size: 1.6rem;
      font-weight: 600;
      padding: 0rem 2rem;
    }

    &--job:hover,
    &--applicant:hover {
      cursor: pointer;
    }
  }

  &--btn {
    padding: 1rem 2.4rem;
    font-size: 1.6rem;
    border-radius: 4px;
    background-color: #009ae0;
    color: #ffffff;
    outline: none;
    border: none;
    cursor: pointer;
    transition: all 0.3s ease-in;
    margin-right: 10rem;

    @include respond(phone) {
      margin-right: 1rem;
      padding: 1rem 2.4rem;
    }
  }
}

.scrollButton {
  border-radius: 50px;
  font-weight: 600;
  padding: 1rem 3.1rem;
}

// ------------ NavHeader ------------- //
.navHeader {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: #0e3a62;
  position: fixed;
  top: 0;
  z-index: 10;

  &__logoDiv {
    margin-left: 6rem;
    display: flex;
    flex-direction: row;
    align-items: center;

    &--clp {
      margin-right: 4rem;

      @include respond(phone) {
        width: 15rem;
        margin-right: 1rem;
      }
    }

    &--applicant {
      font-size: 1.5rem;
      font-weight: 600;
      height: 100%;
      padding: 0 2.5rem;

      @include respond(phone) {
        // padding: 0 1rem;
        display: none;
      }
    }

    &--job {
      font-size: 1.5rem;
      font-weight: 600;
      height: 100%;
      padding: 0 1.5rem;

      @include respond(phone) {
        // padding: 0 0.6rem;
        // margin-right: 1rem;
        display: none;
      }
    }
    &--job:hover,
    &--applicant:hover {
      cursor: pointer;
    }

    @include respond(phone) {
      margin-left: 0.5rem;
    }
  }

  &__button {
    margin-right: 6rem;
    display: flex;
    flex-direction: row;
    align-items: center;

    &--btn {
      padding: 1rem 2rem;
      border-radius: 4px;
      background: #009ae0;
      color: #ffffff;
    }

    &--threeDots {
      display: none;
      @include respond(phone) {
        display: flex;
      }
    }

    &--threeDots img {
      @include respond(phone) {
        width: 20px;
      }
    }

    @include respond(phone) {
      margin-right: 0.5rem;
      padding: 1rem 0;
    }
  }

  &__button {
    &--btn:hover {
      cursor: pointer;
    }
  }

  &__More {
    position: absolute;
    width: 40%;
    bottom: -7rem;
    right: 1.6rem;
    z-index: 100;
    display: flex;
    flex-direction: column;
    background: #ffffff;
    margin-top: 5rem;
    border-radius: 4px;
    border: 1px solid #e5e5e5;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
    &--applicant {
      padding: 1rem;
    }
    &--job {
      padding: 1rem;
    }
  }
}

.threeDots {
  display: none;
  @include respond(phone) {
    display: flex;
  }
}

.threeDots img {
  @include respond(phone) {
    width: 15%;
  }
}
