.Content1 {
  margin-top: 2rem;
  &__Head {
    font-weight: 600;
    font-size: 1.7rem;
    line-height: 150%;
    color: #333333;
    margin-bottom: 0.4rem;
  }
  &__Para {
    font-weight: 450;
    font-size: 1.6rem;
    line-height: 150%;
    color: rgba(0, 0, 0, 0.6);
  }
}
