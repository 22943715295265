.RegisterNow {
  margin-top: 1.6rem;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  border-radius: 6px;
  padding-top: 1.9rem;

  &__Head {
    background: #ffecb1;
    border-radius: 0px 2px 2px 0px;
    font-weight: 500;
    font-size: 1.2rem;
    line-height: 150%;
    letter-spacing: 0.5px;
    color: #694a00;
    padding-top: 0.4rem;
    text-align: center;
    width: 50%;
  }

  &__Body {
    width: 100%;
    display: flex;
    flex-direction: row;

    &--Col1 {
      width: 60%;
      padding: 1.5rem 0 1.5rem 2.4rem;

      &__Para1 {
        font-weight: bold;
        font-size: 1.8rem;
        line-height: 150%;
        color: rgba(0, 0, 0, 0.87);
        margin-bottom: 0.5rem;
      }

      &__Para2 {
        font-weight: 450;
        font-size: 1.6rem;
        line-height: 150%;
        color: rgba(0, 0, 0, 0.6);
        margin-bottom: 1.5rem;
      }

      &__Btn {
        background: #9F5EB5;
        border-radius: 4px;
        font-weight: 500;
        font-size: 1.6rem;
        line-height: 150%;
        color: #ffffff;
        padding: 0.8rem 3rem;
        border: none;
        margin-bottom: 0.8rem;
      }

      &__BtnDone {
        background: #ffffff;
        border: 1px solid #e5e5e5;
        box-sizing: border-box;
        border-radius: 4px;
        font-weight: 500;
        font-size: 1.6rem;
        line-height: 150%;
        color: #348186;
        padding: 0.8rem 3rem;
        margin-bottom: 0.8rem;
        display: flex;
        flex-direction: row;
        align-items: center;

        &--Para {
          margin-left: 0.8rem;
        }
      }

      &__Warning {
        font-weight: 450;
        font-size: 1.2rem;
        line-height: 150%;
        color: #ff4058;
      }
    }

    &--Col2 {
      width: 40%;

      &__Img {
        width: 80%;
        float: right;
      }
    }
  }

  &__Footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 1.6rem;
    background: #fafafa;
    border-top: 1px solid #e5e5e5;
    box-sizing: border-box;

    &--Para {
      font-weight: 450;
      font-size: 1.4rem;
      line-height: 150%;
      color: rgba(0, 0, 0, 0.87);
      margin-left: 2rem;
    }
  }
}
