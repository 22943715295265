@import "abstracts/mixins";
@import "abstracts/variables";

@import "base/animations";
@import "base/base";
@import "base/typography";
@import "base/utilities";

@import "layouts/footer";
@import "layouts/navigation";

@import "components/kidsCodingWeek";
@import "components/home";
@import "components/registerNow";
@import "components/quizDay";
@import "components/upComingEvent";
@import "components/eventComponent";
@import "components/selectClass";
@import "components/registerModal";
@import "components/upComingModal";
@import "components/content1";
@import "components/imageComponent";
@import "components/award";
@import "components/header";
@import "components/quizForm";
@import "components/previousWinner";
@import "components/winners";
@import "components/prizeComponent";
@import "components/quizOfDay";
@import "components/winnerName";

button:focus {
    outline: none;
}