.CustomUser {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  font-size: 15px;
  line-height: 15px;
  text-transform: uppercase;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

@media (max-width: 376px) {
  .CustomUser {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    font-size: 13px;
  }
}
