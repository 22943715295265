.PrizeComponent {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid #e5e5e5;

  &__Col1 {
    width: 60%;
    padding: 2.4rem 0 2.4rem 2.4rem;
    &--Para1 {
      font-weight: bold;
      font-size: 1.8rem;
      line-height: 150%;
      color: rgba(0, 0, 0, 0.87);
      margin-bottom: 1rem;
    }
    &--Para2 {
      font-weight: 450;
      font-size: 1.6rem;
      line-height: 150%;
      color: rgba(0, 0, 0, 0.6);
      width: 90%;
    }
  }
  &__Col2 {
    width: 40%;
    &--Img {
      width: 100%;
      height: 100%;
    }
  }
}
