.WinnerName {
  padding: 1.9rem 2.4rem 0;
  display: flex;
  flex-direction: row;
  align-items: center;

  &__Content {
    margin-left: 1.6rem;

    &--Para1 {
      font-weight: 450;
      font-size: 1.6rem;
      line-height: 2rem;
      color: rgba(0, 0, 0, 0.87);
      margin-bottom: 0.5rem;
    }

    &--Para2 {
      font-weight: 450;
      font-size: 1.4rem;
      line-height: 1.8rem;
      color: rgba(0, 0, 0, 0.6);
    }
  }
}
