.Winners {
  &__Banner {
    margin-top: 6rem;
    width: 100%;
  }
  &__Tab {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background: #ffffff;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.12);

    &--Data {
      padding: 0 1.6rem 1.6rem;
    }
  }
  &__Content {
    padding: 2.2rem;
    padding-top: 0;
  }
}
