.UpcomingEvents {
  margin-top: 2.4rem;

  &__Head {
    font-weight: 450;
    font-size: 2.2rem;
    line-height: 150%;
    color: rgba(0, 0, 0, 0.87);
  }
}
