.Awards {
  margin-top: 2.4rem;

  &__Head {
    font-weight: 450;
    font-size: 2.2rem;
    line-height: 150%;
    color: rgba(0, 0, 0, 0.87);
  }

  &__Body {
    background: #ffffff;
    border: 1px solid #e5e5e5;
    box-sizing: border-box;
    border-radius: 6px;
    padding: 2.4rem;

    &--Para {
      font-weight: 450;
      font-size: 1.6rem;
      line-height: 150%;
      color: rgba(0, 0, 0, 0.6);
    }

    &--Row1 {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 2rem;
      align-items: baseline;
      text-align: center;
      margin-bottom: 2.4rem;
    }
    &--Row2 {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 2rem;
      text-align: center;
    }
  }
}
